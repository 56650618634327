<template>

       <v-col cols="12">
            <v-radio-group  :readonly="disabled" :disabled="disabled" hide-details="auto" inline :label="label" @update:modelValue="setValue" :model-value="getValue" >
                <v-radio  @click="uncheckRadio" class="pe-3" v-for="(option,key) in options" :key="key" :label="option" :value="option"></v-radio>
            </v-radio-group>
        </v-col>
</template>

<script >
import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { defineStore,storeToRefs } from 'pinia';
import { answerStoreObject} from '@/stores/answer.js';
import AssetCreateViewVue from '../../views/AssetCreateView.vue';
import { useAssetGradeStore } from '../../stores/AssetGrade';
export default {
    props: ['label', 'options', 'question', 'answerTypeId', 'surveyAnswersStore', 'disabled', 'answer', 'recommended_fill'],


    methods: {
        uncheckRadio(event) {
            if (this.answer.value == event.target.value) {
                this.setValue(null);
            }
        },
        setValue(value) {
            if(!this.disabled) {
                this.answer.value = value

                console.log(this.recommended_fill && !(value in this.recommended_fill));

                if(!this.isPrePopulated){
                    this.answerStore.saveAnswer(this.question_answer_type);
                }
                if( this.recommended_fill && value in this.recommended_fill) {
                    console.log('in recommended Fill')
                    this.assetGradeStore.addRecommendedFill(this.recommended_fill[value])
                }
                else if( this.recommended_fill && !(value in this.recommended_fill)) {
                    console.log('remove fill')
                    this.assetGradeStore.removeRecommendedFill(this.recommended_fill)
                }

            }
        }
    },
    computed: {
        getValue() {
            try {
                return JSON.parse(this.answer?.value)
            } catch (e) {
                return this.answer?.value
            }
        }
    },
    mounted() {
        if(!this.answerStore && !this.isPrePopulated){
            this.answerStore = defineStore (uuidv4(), answerStoreObject)()
        }
        if(!this.isPrePopulated) {
            this.answer =  storeToRefs(this.answerStore)
        }
    },


    setup(props,context) {
        const question_answer_type =  props.answer ? props.answer.question_answer_type : props.question.question_answer_types.find((question_answer_type) => (question_answer_type.question_id == props.question.id) &&  (question_answer_type.answer_type_id ==  props.answerTypeId))
        const answerStore =  props.surveyAnswersStore ? props.surveyAnswersStore : ref()
        const answer =   props.answer? props.answer : ref()
        const isPrePopulated =  !!props.answer

        const assetGradeStore = useAssetGradeStore();
        return {
            answerStore,
            answer,
            isPrePopulated,
            question_answer_type,
            assetGradeStore,
        }
    }
}
</script>
