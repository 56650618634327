<template>

    <v-col cols="12">
        <template v-if="disabled && getValue">
            <v-textarea :readonly="disabled" :disabled="disabled"  :label="label"  :model-value="getValue"></v-textarea>
        </template>

        <v-textarea  v-else :readonly="disabled" :hint="helpText" class="text-black" hide-details="auto" :label="label" @update:model-value="setValue" :model-value="getValue" persistent-hint></v-textarea>

    </v-col>
</template>

<script >
import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { defineStore,storeToRefs } from 'pinia';
import { answerStoreObject} from '@/stores/answer.js';

export default {
    props: ['label', 'question', 'answerTypeId', 'surveyAnswersStore', 'disabled', 'answer', 'index'],


    methods: {
        setValue(value) {
            if(!this.disabled) {
                this.answer.value = value
                if(!this.isPrePopulated){

                    this.answerStore.saveAnswer(this.question_answer_type);
                }
            }
        }
    },
    computed: {
        getValue() {
            try {
                return JSON.parse(this.answer?.value)
            } catch (e) {
                return this.answer?.value
            }
        },

        helpText () {
            if(this.question?.answer_types) {
                return this.question?.answer_types?.findIndex((answer_type)=> answer_type.component_name == 'answer-type-details') == this.index -1? this.question.help_text :'';
            }
        }
    },
    mounted() {
        if(!this.answerStore && !this.isPrePopulated){
            this.answerStore = defineStore (uuidv4(), answerStoreObject)()
        }
        if(!this.isPrePopulated) {
            this.answer =  storeToRefs(this.answerStore)
        }
    },


    setup(props,context) {
        const question_answer_type =  props.answer ? props.answer.question_answer_type : props.question.question_answer_types.find((question_answer_type) => (question_answer_type.question_id == props.question.id) &&  (question_answer_type.answer_type_id ==  props.answerTypeId))
        const answerStore =  props.surveyAnswersStore ? props.surveyAnswersStore : ref()
        const answer =   props.answer? props.answer : ref()
        const isPrePopulated =  !!props.answer
        return {
            answerStore,
            answer,
            isPrePopulated,
            question_answer_type
        }
    }
}
</script>
