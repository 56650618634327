<template>

    <v-col cols="12">
        <v-text-field  :readonly="disabled" :disabled="disabled" hide-details="auto" :label="label1" @update:model-value="setValueWidth" :model-value="getValue[0]"></v-text-field>
        <v-text-field  :readonly="disabled" :disabled="disabled" hide-details="auto" :label="label2" @update:model-value="setValueHeight" :model-value="getValue[1]"></v-text-field>
    </v-col>
</template>

<script >
import { ref,defineProps,reactive  } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { defineStore,storeToRefs } from 'pinia';
import { answerStoreObject} from '@/stores/answer.js';

export default {
    props: ['label1', 'label2', 'question', 'answerTypeId', 'surveyAnswersStore', 'disabled','answer'],

    methods: {
        setValueWidth(value) {

            this.width = value
            if(!this.height && this.answer?.value) {
                this.height = this.answer.value.replace(/mm/g,'').split(' x ')[1]
            }
            this.answer.value = this.width + 'mm x ' + (this.height ? this.height  + 'mm': '')
            if(!this.isPrePopulated) {
                this.answerStore.saveAnswer(this.question_answer_type);
            }
        },
        setValueHeight(value) {
            this.height = value
            if(!this.width && this.answer?.value) {
                this.width = this.answer.value.replace(/mm/g,'').split(' x ')[0]
            }
            this.answer.value = (this.width ? this.width : '') + 'mm x ' + this.height + 'mm'
            if(!this.isPrePopulated) {
                this.answerStore.saveAnswer(this.question_answer_type);
            }
        },
    },
    computed: {
        compValue() {
            try {
                return JSON.parse(this.answer?.value)
            } catch (e) {
                return this.answer?.value
            }
        },
        getValue() {
            let value =  this.compValue
            value = value?.replace(/mm/g,'').split(' x ')

            return value?.length ? value : [null,null]
        },
    },
    mounted() {
        if(!this.answerStore && !this.value){
            this.answerStore = defineStore (uuidv4(), answerStoreObject)()
        }
        if(!this.answer && !this.value) {
            this.answer =  storeToRefs(this.answerStore)
        }
    },

    setup(props,context) {
        const question_answer_type =  props.answer ? props.answer.question_answer_type : props.question.question_answer_types.find((question_answer_type) => (question_answer_type.question_id == props.question.id) &&  (question_answer_type.answer_type_id ==  props.answerTypeId))
        const answerStore =  props.surveyAnswersStore ? props.surveyAnswersStore : ref()
        const answer =   props.answer? props.answer : ref()
        const isPrePopulated =  !!props.answer
        return {
            answerStore,
            answer,
            isPrePopulated,

            question_answer_type,
        }
    }
}
</script>
