<script setup>
import { useUserStore } from '@/stores/user';
import { useUsersStore } from '@/stores/users.js';
import { useInspectionsStore } from '@/stores/inspections.js';
import { useAssetsStore } from '@/stores/assets.js';
import { useSitesStore } from '@/stores/sites.js';
import { useAssetTypesStore } from '@/stores/assetTypes.js';
import { useSurveyAnswersStore } from '@/stores/surveyAnswers.js';
import { useAssetGradesStore } from '@/stores/AssetGrades';
import { useFilesStore } from '@/stores/files';
import { useAppStore } from '@/stores/app';
import { useAssetStore } from '@/stores/asset';
import { useInspectionStore } from '@/stores/inspection';
import { useSiteStore } from '@/stores/site';
import { useUserModelStore } from '@/stores/userModel';
import { useAssetGradeStore } from '@/stores/AssetGrade';

// ✅ This will work because the router starts its navigation after
// the router is installed and pinia will be installed too
const appStore = useAppStore();
const userStore = useUserStore();
const usersStore = useUsersStore();
const sitesStore = useSitesStore();
const assetsStore = useAssetsStore();
const assetTypesStore = useAssetTypesStore();
const inspectionsStore = useInspectionsStore();
const surveyAnswersStore = useSurveyAnswersStore ();
const assetGradeStore = useAssetGradesStore ();
const filesStore = useFilesStore();
const assetStore = useAssetStore();
const inspectionStore = useInspectionStore();
const siteStore = useSiteStore();
const userModel = useUserModelStore();
const assetGrade = useAssetGradeStore();

userStore.initUser()

const loadedInterval = setInterval(async function() {
    if(userStore.isLoggedIn()) {
        if(userStore.isAdmin ()) {

            usersStore.sync()
        }
        assetsStore.sync()
        sitesStore.sync()
        inspectionsStore.sync()
        assetTypesStore.sync()
        assetGradeStore.sync()
        filesStore.sync()
        appStore.setLoaded()
        clearInterval(loadedInterval);
    }
}, 5000)

setInterval(async function() {
    console.log("Calling sync interval.")

    if (userStore.user) {
        if(userStore.isAdmin ()) {
            usersStore.sync()
        }

        assetsStore.sync()
        sitesStore.sync()
        inspectionsStore.sync()
        assetTypesStore.sync()
        assetGradeStore.sync()
        filesStore.sync()
    }

}, 60 * 1000); // 60 * 1000 milsec

</script>

<template>
    <div>
        <router-view  :key="$route.fullPath+'layout'" v-slot="{ Component }">
            <transition name="slide-left">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>
