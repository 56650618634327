import './bootstrap';
import './assets/main.css'

import router from './router'

import { createApp,markRaw, watch } from 'vue';
import { createPinia } from 'pinia'


import App from './App.vue'
import { indexDbPlugin } from './plugins/PiniaIndexedDBPersistence';
import { syncPlugin } from './plugins/Sync';
import  vuetify from './plugins/vuetify';
import DoughnutChart from './components/DoughnutChart.vue';
import * as Sentry from "@sentry/vue";
import { registerSW } from 'virtual:pwa-register'

import './assets/fontawesome/css/all.css'
import './assets/fontawesome/css/fontawesome.css'

// import { useAssetsStore } from './stores/assets';
// if ("serviceWorker" in navigator) {
// 	window.addEventListener("load", function() {
// 	  navigator.serviceWorker
// 		.register("/ServiceWorker.js")
// 		.then(res => console.log("service worker registered"))
// 		.catch(err => console.log("service worker not registered", err))
// 	})
//   }
// add this to prompt for a refresh
// const updateSW = registerSW({
//     onNeedRefresh() {
//       if (confirm("New content available. Reload?")) {
//         updateSW(true);
//       }
//     },
//   });

const app = createApp(App)



registerSW({
 immediate: true,
  onOfflineReady() {
    console.log("PWA IS OFFLINE READY")
  },
})

const pinia = createPinia()
pinia.use(({ store }) => {
    store.router = markRaw(router);
    store.currentRoute = store.router.currentRoute?.value;
});

pinia.use(indexDbPlugin)

pinia.use(syncPlugin)
app.use(vuetify)
//bootstrap required stores

app.use(pinia)

Sentry.init({
    app: [app],
    dsn: "https://44342ff5748c46c4ae001744f1fca069@sentry.netready.biz/27",
    tracesSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],
    enabled: import.meta.env.VITE_ENV !== 'local',
  });

const components = import.meta.globEager('./components/answer-types/*.vue')

Object.entries(components).forEach(([path, definition]) => {
    // Get name of component, based on filename
    // "./components/Fruits.vue" will become "Fruits"
    const componentName = path.split('/').pop().replace(/\.\w+$/, '')

    // Register component on this Vue instance
    app.component(componentName, definition.default)
})

app.component('DoughnutChart', DoughnutChart)
app.use(router)
// useAssetsStore()
app.mount('#app')

