<template>

    <v-col cols="12">
        <v-img  @click="overlay = true" v-if="url" :src="url"  contain  height="150"  />
        <v-overlay v-if="url" v-model="overlay"     class="align-center justify-center">
            <v-card  width="100vw">
            <v-img   :src="url"  contain   />
            </v-card>
        </v-overlay>
        <v-btn-outline v-if="url" @click="clear" block border="0" icon="far fa-trash" class="text-right"></v-btn-outline>
        <v-file-input ref="image" @click:clear="answer.value = null" :readonly="disabled" hide-details="auto"  prepend-icon="" prepend-inner-icon="far fa-camera" :label="label" @input="setValue"></v-file-input>
    </v-col>
</template>

<script>
import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { defineStore,storeToRefs } from 'pinia';
import { answerStoreObject} from '@/stores/answer.js';
import { useFilesStore } from '../../stores/files';
import { useFileStore } from '@/stores/file';

export default {
    props: ['label','question', 'answerTypeId', 'surveyAnswersStore', 'disabled', 'answer'],


    methods: {
        setValue(event) {
            // this.answer.value = event.target.files[0]
            // if(!this.isPrePopulated) {
            const fileStore = useFileStore()
            fileStore.storeFile(event.target.files[0], this)
            this.url = this.answer.value
            this.answerStore.saveAnswer(this.question_answer_type);
            // }
        },
        clear() {
            this.answer.value = null;
            this.$refs.image.reset()
        }
    },
    computed: {
        getValue() {
            try {
                return JSON.parse(this.answer?.value)
            } catch (e) {
                return this.answer?.value
            }
        },
        url() {
            const filesStore =  useFilesStore()
            let value = this.getValue
            if (!value || value == 'null') return;
            if(File.prototype.isPrototypeOf(value)){
                return URL.createObjectURL(value);
            }

            return filesStore.getFileById(value)?.base64
        }
    },
    mounted() {
        if(!this.answerStore){
            this.answerStore = defineStore (uuidv4(), answerStoreObject)()
        }
        if(!this.answer) {
            this.answer =  storeToRefs(this.answerStore)
        }
    },

    setup(props,context) {
        const question_answer_type =  props.answer ? props.answer.question_answer_type : props.question.question_answer_types.find((question_answer_type) => (question_answer_type.question_id == props.question.id) &&  (question_answer_type.answer_type_id ==  props.answerTypeId))
        const answerStore =  props.surveyAnswersStore ? props.surveyAnswersStore : ref()
        const answer =   props.answer? props.answer : ref()
        const isPrePopulated =  !!props.answer

        const overlay =  ref(false)
        return {
            answerStore,
            answer,
            isPrePopulated,
            question_answer_type,
            overlay
        }
    }
}
</script>
